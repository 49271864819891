import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRouter } from "./components";
import { StoreProvider } from "./store";
import { ViewPage } from "./cms/pages/viewPage/viewPage";
import { EditPage } from "./cms/pages/editPage/editPage";
import { TopHeader } from "./layout/topHeader/topHeader2";
import { TopHeaderQR } from "./layout/topHeaderQR/topHeaderQR";
//import { Footer } from './layout/footer/footer';
import { BottomDock } from "./layout/bottomDock/bottomDock";
import { ProductView } from "./site/product/productView";
import { DiscontinuedProductView } from "./site/discontinuedProduct/productView";
// import { ProductView as ProductViewTest } from './site/product/productView-test';
// import { BlogView } from './site/blog/blogView';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
//import { useParallax } from './components';
import { AppVersionPage } from "./components";
import { MediaQueryStatus } from "./components";

// import { CurlSecretLoading } from "./site/curlSecret/curlSecretLoading";
// import { CurlSecretLoadingV2 } from "./site/curlSecretV2/curlSecretLoadingV2";
// import { CurlSecretLoadingV2QR } from "./site/curlSecretV2QR/curlSecretLoadingV2QR";
// import { CurlSecretLoadingV2NZ } from "site/curlSecretV2NZ/curlSecretLoadingV2";
// import { CurlSecretLoadingV3 } from "site/curlSecretV3/curlSecretLoadingV3";
// import { CurlSecretLoadingV3QR } from "site/curlSecretV3QRCode/curlSecretLoadingV3QR";
import { CurlSecretLoadingV4 } from "site/curlSecretV4/curlSecretLoadingV4";
import { CurlSecretLoadingV4NZ } from "site/curlSecretV4NZ/curlSecretLoadingV4";
import { CurlSecretLoadingV4QR } from "site/curlSecretV4QRCode/curlSecretLoadingV4QR";

import { FrizzDefenseLoading } from "./site/frizzDefense/frizzDefenseLoading";
import { KeratinLoading } from "./site/keratin/keratinLoading";
//import { BrillianceLoading } from './site/brilliance/brillianceLoading';
import { BrillianceLoading as BrillianceLoadingV2 } from "./site/brillianceV2/brillianceLoading";
import { BrillianceLoading as BrillianceLoading2 } from "./site/brilliance2/brillianceLoading2";
import { DigitalSensorLoading } from "./site/digitalSensor/digitalSensorLoading";
import { UnboundLoading } from "./site/unbound/unboundLoading";

import { HydroSmoothLoading } from "./site/hydroSmooth/hydroSmoothLoading";
import { HydroSmoothLoading as HydroSmoothLoadingV2 } from "./site/hydroSmoothV2/hydroSmoothLoading";

import { PetiteLoading } from "./site/petite/petiteLoading";
import { PetiteLoadingNz } from "site/petiteNz/petiteLoadingNz";
import { ShineLustreLoading } from "./site/shineLustre/shineLustreLoading";

import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from "react-helmet-async";
import { AppRouteChanged } from "./AppRouteChanged";
import { checkUpdate } from "./lib/service-worker/swHelper";
import { DigitalSensorLuxeLoading } from "./site/digitalSensorLuxe/digitalSensorLuxeLoading";
import YoutubeTest from "site/youtube-test/digitalSensorLuxe";
import utils from "./utils";

function AppLayout(props) {
  const { pathname } = useRouter();
  console.log("pathname", pathname);

  React.useEffect(() => {
    const fetchData = async () => {
      await checkUpdate();
    };
    fetchData();
  }, [pathname]);

  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        document.querySelector("body").classList.add("scrolled");
      } else {
        document.querySelector("body").classList.remove("scrolled");
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))
  const pathFiltered = (pathname || "").toLocaleLowerCase();

  let specialClassName = "";
  if (
    pathFiltered === "/digital-sensor-luxe" ||
    pathFiltered === "/digital-sensor-luxe-test"
  )
    specialClassName = "digital-sensor-luxe-page";
  else if (pathFiltered.indexOf("/digital-sensor") >= 0)
    specialClassName = "digital-sensor-page";
  else if (pathFiltered === "/brilliance") specialClassName = "brilliance-page";
  else if (pathFiltered === "/brilliance-test")
    specialClassName = "brilliance-page";
  else if (
    pathFiltered === "/curl-secret" ||
    pathFiltered === "/curl-secret-stg-au" ||
    pathFiltered === "/curl-secret-stg-nz"
  )
    specialClassName = "curl-secret-page";
  else if (
    pathFiltered === "/curl-secret-qrcode" ||
    pathFiltered === "/curl-secret-stg-qrcode"
  )
    specialClassName = "curl-secret-qrcode-page";
  else if (pathFiltered === "/unbound") specialClassName = "unbound-page";
  else if (
    pathFiltered === "/hydro-smooth" ||
    pathFiltered === "/hydro-smooth-stg"
  )
    specialClassName = "hydroSmooth-page";
  else if (pathFiltered === "/steam-straight-brilliance")
    specialClassName = "steam-straight-brilliance-page";
  else if (pathFiltered === "/shine-lustre-test")
    specialClassName = "shine-lustre-page";

  if (
    pathname.includes("/products/curlers/") ||
    pathname.includes("/products/dryers/") ||
    pathname.includes("/products/straighteners/") ||
    pathname.includes("/products/stylers/")
  ) {
    const _pathName = pathname
      .replace("/curlers/", "/")
      .replace("/dryers/", "/")
      .replace("/straighteners/", "/")
      .replace("/stylers/", "/");
    return <Redirect to={{ pathname: _pathName, state: { status: 301 } }} />;
  }

  if (!utils.site.isNZ && pathname === "/curl-secret-qrcode") {
    return (
      <div className={`body-content subpage ${specialClassName}`}>
        <TopHeaderQR></TopHeaderQR>
        <div className='main-content'>
          <div className='app-content'>
            <CurlSecretLoadingV4QR />
          </div>
        </div>
      </div>
    );
  } else if (!utils.site.isNZ && pathname === "/curl-secret-stg-qrcode") {
    return (
      <div className={`body-content subpage ${specialClassName}`}>
        <TopHeaderQR></TopHeaderQR>
        <div className='main-content'>
          <div className='app-content'>
            <CurlSecretLoadingV4QR />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`body-content ${
          pathname === "/" ? "homepage" : "subpage"
        } ${specialClassName}`}
      >
        <AppRouteChanged />
        <TopHeader></TopHeader>
        <div className='main-content'>
          <div className='app-content'>
            <Switch>
              <Route path='/ver' children={<AppVersionPage />} />
              <Route path='/cms/page/render/:id' children={<EditPage />} />
              <Route path='/product/:id' children={<ProductView />} />
              {/* <Route path='/products/:category/:slug/test' children={<ProductViewTest />} /> */}

              <Route
                path='/products/:category/:slug'
                children={<ProductView />}
              />
              <Route path='/products/:slug' children={<ProductView />} />

              <Route path='/discontinued-products/:id' children={<DiscontinuedProductView />} />
              <Route path='/discontinued-products/:category/:slug' children={<DiscontinuedProductView />} />
              <Route path='/discontinued-products/:slug' children={<DiscontinuedProductView />} />

              {/* <Route path="/straighteners/:subCategory/:slug" children={<ProductView />} /> */}
              {/* <Route path="/dryers/:subCategory/:slug" children={<ProductView />} />
              <Route path="/curlers/:subCategory/:slug" children={<ProductView />} /> */}

              {/* <Route path="/blog/:id" children={<BlogView />} /> */}

              {!utils.site.isNZ && (
                <Route path='/curl-secret' children={<CurlSecretLoadingV4 />} />
              )}

              {utils.site.isNZ && (
                <Route
                  path='/curl-secret'
                  children={<CurlSecretLoadingV4NZ />}
                />
              )}
              {/* NZ - for presenting */}
              {/* {!utils.site.isNZ && (
                <Route
                  path='/curl-secret-stg-nz'
                  children={<CurlSecretLoadingV4NZ />}
                />
              )} */}

              {/* {!utils.site.isNZ && (
                <Route
                  path='/curl-secret-stg-au'
                  children={<CurlSecretLoadingV4 />}
                />
              )} */}

              {/* <Route path="/curl-secret-stg" children={<CurlSecretLoadingV2 />} /> */}
              <Route path='/frizz-defense' children={<FrizzDefenseLoading />} />
              <Route path='/keratin' children={<KeratinLoading />} />
              <Route path='/keratin-protect' children={<KeratinLoading />} />
              {/* <Route path='/brilliance' children={<BrillianceLoading />} /> */}
              <Route path='/brilliance' children={<BrillianceLoadingV2 />} />
              <Route
                path='/steam-straight-brilliance'
                children={<BrillianceLoading2 />}
              />
              <Route
                path='/digital-sensor-luxe'
                children={<DigitalSensorLuxeLoading />}
              />
              <Route
                path='/digital-sensor-range'
                children={<DigitalSensorLoading />}
              />
              <Route
                path='/digital-sensor'
                children={<DigitalSensorLoading />}
              />
              <Route path='/unbound' children={<UnboundLoading />} />

              {/* <Route path="/hydro-smooth" children={<HydroSmoothLoading />} />
              <Route path="/hydro-smooth-stg" children={<HydroSmoothLoadingV2 />} /> */}

              <Route
                path='/hydro-smooth'
                children={
                  utils.site.isNZ ? (
                    <HydroSmoothLoading />
                  ) : (
                    <HydroSmoothLoadingV2 />
                  )
                }
              />

              <Route path='/petite' children={<PetiteLoadingNz />} />

              {/*<Route path='/petite-stg-nz' children={<PetiteLoadingNz />} />*/}
              
              <Route path='/shine-lustre' children={<ShineLustreLoading />} />
              <Route path='/youtube-test' children={<YoutubeTest />} />

              <Route path='/admin'>
                <Redirect to='/admin' />
              </Route>
              <Route path='/'>
                <ViewPage />
              </Route>
            </Switch>
          </div>
          {/* <app-common-bottom-partial></app-common-bottom-partial> */}
        </div>
        {/* <Footer></Footer> */}
        <BottomDock></BottomDock>
        <MediaQueryStatus />
        <CommonVideoPopup />
      </div>
    );
  }
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
        <Router>
          <AppLayout />
        </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;
